/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 .\cottonplant.glb
Author: Thunder (https://sketchfab.com/thunderpwn)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/cottonplant-0918412ae8ce463192b117e5b901c81c
Title: Cottonplant
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Cotton(props) {
  const { nodes, materials } = useGLTF('../../modelos/cottonplant.glb')
  return (
    <group {...props} dispose={null}>
      <group scale={0.17}>
        <mesh geometry={nodes.Icosphere021__0.geometry} material={materials['Scene_-_Root']} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
    </group>
  )
}

useGLTF.preload('../../modelos/cottonplant.glb')
