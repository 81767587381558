/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 .\pumpkin.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Pumpkin(props) {
    const { nodes, materials } = useGLTF('../../modelos/pumpkin.glb')
    return (
        <group {...props} dispose={null}>
            <group rotation={[-Math.PI / 2, 0, 0]} scale={2.1}>
                <mesh geometry={nodes.Mesh.geometry} material={materials.pxrUsdPreviewSurface1SG} position={[0.009, 0.003, 1.608]} scale={0.261} />
                <mesh geometry={nodes.Mesh_1.geometry} material={materials.pxrUsdPreviewSurface2SG} position={[0.013, 0, 0.782]} scale={[1, 1, 0.821]} />
                <mesh geometry={nodes.Mesh_2.geometry} material={materials.pxrUsdPreviewSurface3SG} position={[0.783, 1.024, 0.751]} rotation={[-0.173, 0.523, 0.725]} scale={1.46} />
                <mesh geometry={nodes.Mesh_3.geometry} material={materials.pxrUsdPreviewSurface4SG} position={[0.12, 1.241, 0.297]} rotation={[-0.735, 0.068, 0.985]} scale={3.072} />
                <group position={[1.19, 0.023, 0.381]} scale={0.028}>
                    <mesh geometry={nodes.Mesh_5.geometry} material={materials.pxrUsdPreviewSurface6SG} position={[-87.04, -38.564, 22.653]} rotation={[0.667, 0.181, 1.614]} scale={95.253} />
                    <mesh geometry={nodes.Mesh_6.geometry} material={materials.pxrUsdPreviewSurface7SG} position={[-5.541, -35.703, 3.467]} rotation={[-0.157, 0.454, 2.851]} scale={85.604} />
                    <mesh geometry={nodes.Mesh_7.geometry} material={materials.pxrUsdPreviewSurface8SG} position={[5.404, -10.015, 1.843]} rotation={[0.24, 0.494, -1.621]} scale={46.945} />
                    <mesh geometry={nodes.Mesh_8.geometry} material={materials.pxrUsdPreviewSurface9SG} position={[-48.781, -45.928, 14.247]} rotation={[0.481, -0.344, -1.959]} scale={67.347} />
                    <mesh geometry={nodes.Mesh_9.geometry} material={materials.pxrUsdPreviewSurface10SG} position={[8.816, 21.274, 7.609]} rotation={[0.462, 0.723, -0.486]} scale={75.538} />
                    <mesh geometry={nodes.Mesh_4.geometry} material={materials.pxrUsdPreviewSurface5SG} />
                </group>
            </group>
        </group>
    )
}

useGLTF.preload('../../modelos/pumpkin.glb')
