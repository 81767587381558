/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 fence.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Fence(props) {
  const { nodes, materials } = useGLTF('../../modelos/fence.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Fence003.geometry} material={materials.Material} position={[4, 0.5, 0]} />
    </group>
  )
}

useGLTF.preload('../../modelos/fence.glb')
