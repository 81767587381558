/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 boat.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Boat(props) {
  const { nodes, materials } = useGLTF('../../modelos/boat.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[0, 0.741, 0]} scale={0.01}>
        <mesh geometry={nodes.Mesh.geometry} material={materials.Boat_base} />
        <mesh geometry={nodes.Mesh_1.geometry} material={materials.Bench} />
        <mesh geometry={nodes.Mesh_2.geometry} material={materials.Paddle_bottom} />
        <mesh geometry={nodes.Mesh_3.geometry} material={materials.Paddle_top} />
        <mesh geometry={nodes.Mesh_4.geometry} material={materials.Boat_base_lines} />
      </group>
    </group>
  )
}

useGLTF.preload('../../modelos/boat.glb')
