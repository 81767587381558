/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.4 .\pepper.glb
Author: zvanstone (https://sketchfab.com/zvanstone)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/jalapeno-plant-3f11de4c1de5494ab5f60476b42f454f
Title: Jalapeno Plant
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Pepper(props) {
  const { nodes, materials } = useGLTF('../../modelos/pepper.glb')
  return (
    <group {...props} dispose={null} scale={0.03}>
      <mesh geometry={nodes.Object_2.geometry} material={materials.lambert2SG} rotation={[-Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('../../modelos/pepper.glb')
